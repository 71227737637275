exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-cxo-tsx": () => import("./../../../src/pages/cxo.tsx" /* webpackChunkName: "component---src-pages-cxo-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-founder-compensation-calculator-tsx": () => import("./../../../src/pages/founder-compensation-calculator.tsx" /* webpackChunkName: "component---src-pages-founder-compensation-calculator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offers-tsx": () => import("./../../../src/pages/offers.tsx" /* webpackChunkName: "component---src-pages-offers-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-service-agreement-tsx": () => import("./../../../src/pages/service-agreement.tsx" /* webpackChunkName: "component---src-pages-service-agreement-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

